import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export const PreviewImage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "home-video-preview.jpg" }) {
        childImageSharp {
          fixed(width: 1920, height: 1080) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <img
      alt={"Brad & Edrienne"}
      src={data.file.childImageSharp.fixed.src}
      {...props}
    />
  )
}
