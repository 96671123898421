import {Link} from "gatsby";
import React, {useEffect, useState} from "react"

import { PreviewImage } from "../components/home/preview-image"
import SEO from "../components/seo"

import "./index.css"

const NotFoundPage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [])

  return (
    <>
      <SEO title="Not found"/>
      <div className="container overlay"/>
      <div className="container">
        <PreviewImage className="full-page-background" />
      </div>
      <div
        className="animate-opacity hero text"
        style={{ opacity: (loading) ? 0 : 1 }}
      >
        <div>You go that way...</div>
        <div><Link to="/">I'll go home... (no seriously, click me to go home)</Link></div>
      </div>
    </>
  )
}

export default NotFoundPage
